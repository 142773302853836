@import '../../../../scss/theme-bootstrap';

$swatch_size: (100% / 8);
$shades-hover-padding: 4px;

.product-brief-shades {
  padding: 0;
  margin: 0;
  margin-top: -($shades-hover-padding);
  list-style: none;
  &__grid {
    direction: #{$text-direction};
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 0;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
    }
  }
  &__shade {
    position: relative;
    height: 44px;
    transition: all 0.2s ease-in-out;
    display: block;
    width: 100%;
    @include breakpoint($bp--medium-up) {
      height: 18px;
      margin-top: $shades-hover-padding;
      margin-bottom: $shades-hover-padding;
    }
    &.selected {
      box-shadow: inset 0 0 0 4px $color--black--opacity-6;
      @include breakpoint($bp--medium-up) {
        box-shadow: inset 0 0 0 2px $color--black;
      }
    }
    @if $cr21_mpp == false {
      &:before {
        @include breakpoint($bp--medium-up) {
          content: ' ';
          display: none;
          width: inherit;
          height: inherit;
          padding-top: 8px;
          background-color: inherit;
          margin-top: -($shades-hover-padding);
        }
      }
    }
    &:hover:before {
      @include breakpoint($bp--medium-up) {
        display: inline-flex;
      }
    }
    &:hover {
      @include breakpoint($bp--medium-up) {
        display: inline-flex;
      }
    }
    &.selected:hover:before {
      @include breakpoint($bp--medium-up) {
        box-shadow: inset 0 0 0 2px $color--black;
      }
    }
    &--single.selected {
      box-shadow: none;
    }
    &--single.selected:hover:before {
      box-shadow: none;
    }
    &.light-shade {
      border: 1px solid $color--gray--light;
    }
  }
  &__shade-item {
    overflow: visible;
    display: inline-block;
    height: auto;
    width: $swatch_size;
    @include breakpoint($bp--medium-up) {
      margin: 0;
      padding: 0;
    }
    a,
    a:hover {
      border: none;
    }
  }
  .slick-list {
    width: 100%;
    @include breakpoint($bp--medium-up) {
      margin-top: 0;
    }
  }
  .slick-track {
    padding: 0;
    margin: 0 auto;
  }
  .slick-prev,
  .slick-next {
    z-index: 100;
    min-width: 15px;
    min-height: 30px;
    top: 13px;
    position: absolute;
    @include breakpoint($bp--medium-up) {
      top: 2px;
    }
    &:before {
      display: none;
    }
    img {
      max-width: 39%;
      margin-top: -5px;
    }
    &.slick-disabled {
      visibility: hidden;
    }
    &.disable-arrow {
      opacity: 0.3;
      cursor: auto;
    }
  }
  .slick-prev {
    #{$ldirection}: 0;
  }
  .slick-next {
    #{$rdirection}: 0;
  }
  .slick-next,
  .slick-next:before,
  .slick-prev,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1.7;
    color: $color--white;
  }
  [data-shade-count='1'] {
    .product-brief-shades__shade {
      &.selected {
        box-shadow: none;
        &.low-contrast {
          @include low-contrast-shadow;
        }
      }
      &.selected:hover:before {
        box-shadow: none;
      }
    }
  }
}
